import { memo } from 'react'

import styled from '@emotion/styled'
import { Box, Flex, Heading } from '@inspectornexus/components'

import { AppFooterNavItem } from './AppFooterNavItem'

const Container = styled(Box)`
  white-space: nowrap;
`

export const AppFooterNav = memo(() => (
  <Container py={7} as="article">
    <Flex
      flexWrap={['wrap', 'nowrap']}
      mx="auto"
      px={4}
      width="100%"
      maxWidth="1040px"
    >
      <Flex
        flex="1"
        justifyContent="space-between"
        flexWrap={['wrap', 'nowrap']}
      >
        <Box mr={[0, 5]} mb={4} flexBasis={['50%', 'auto']}>
          <Heading
            fontSize={1}
            fontWeight={600}
            letterSpacing=".025em"
            mb={2}
            textTransform="uppercase"
            as="h4"
            color="text2"
          >
            Inspector Nexus
          </Heading>
          <Box as="ul">
            <AppFooterNavItem
              label="New Users"
              href="https://web.inspectornexus.com/web"
            />
            <AppFooterNavItem
              label="Existing Users"
              href="https://web.inspectornexus.com/web"
            />
            <AppFooterNavItem label="Sample Reports" href="/sampleReports" />
            <AppFooterNavItem label="Pricing" href="/pricing" />
          </Box>
        </Box>
        <Box mr={[0, 5]} mb={4} flexBasis={['50%', 'auto']}>
          <Heading
            fontSize={1}
            fontWeight={600}
            letterSpacing=".025em"
            mb={2}
            textTransform="uppercase"
            as="h4"
            color="text2"
          >
            Resources
          </Heading>
          <Box as="ul">
            <AppFooterNavItem
              label="Support"
              target="_blank"
              href="https://help.inspectornexus.com"
            />
            <AppFooterNavItem
              label="Service Status"
              target="_blank"
              href="https://inspectornexus.instatus.com/"
            />
            <AppFooterNavItem label="Terms of Use" href="/termsConditions" />
            <AppFooterNavItem label="Privacy Policy" href="/privacyPolicy" />
          </Box>
        </Box>
        <Box mr={[0, 5]} mb={4} flexBasis={['50%', 'auto']}>
          <Heading
            fontSize={1}
            fontWeight={600}
            letterSpacing=".025em"
            mb={2}
            textTransform="uppercase"
            as="h4"
            color="text2"
          >
            Contact Us
          </Heading>
          <Box as="ul">
            <AppFooterNavItem
              label="Email"
              target="_blank"
              href="mailto:support@inspectornexus.com"
            />
          </Box>
        </Box>
      </Flex>
    </Flex>
  </Container>
))

AppFooterNav.displayName = 'AppFooterNav'
