import { memo } from 'react'

import { Link } from 'components/Link'
import type { ILink } from 'utils/navLinks'

import { Text } from '@inspectornexus/components'
import { ChevronRight } from '@inspectornexus/icons'

interface IProps extends ILink {
  lightHeader: boolean
  linkKey: string
}

export const AppHeaderLink = memo(
  ({ lightHeader, label, path, linkProps, linkKey }: IProps) => (
    <Link
      display="inline-block"
      fontSize={3}
      fontWeight={400}
      height="50px"
      href={path}
      lineHeight="50px"
      m={0}
      noSelect
      noWrap
      py={0}
      px={[2, 'calc(-17.16247px + 4.0541vw)' as unknown as number, 4]}
      {...linkProps}
    >
      <Text
        noWrap
        color={lightHeader ? 'lightText' : 'lightText3'}
        position="relative"
        display="inline"
      >
        {label}
      </Text>
      {linkKey === 'login' ? (
        <ChevronRight
          size={16}
          color={lightHeader ? 'lightText' : 'lightText3'}
          style={{ display: 'inline', transform: 'translateY(2px)' }}
        />
      ) : null}
    </Link>
  )
)

AppHeaderLink.displayName = 'AppHeaderLink'
