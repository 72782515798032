import { memo } from 'react'

import { Box } from '@inspectornexus/components'

import { AppFooterCta } from './AppFooterCta'
import { AppFooterNav } from './AppFooterNav'

interface IProps {
  includeCTA: boolean
}

export const AppFooter = memo<IProps>(({ includeCTA }) => (
  <Box backgroundColor="transparent" position="relative" as="footer">
    {includeCTA ? <AppFooterCta /> : null}
    <AppFooterNav />
  </Box>
))

AppFooter.displayName = 'AppFooter'
