import type { ReactNode } from 'react'
import { memo } from 'react'

import NextLink from 'next/link'

import styled from '@emotion/styled'
import type { IBoxProps, ITextProps } from '@inspectornexus/components'
import { Text } from '@inspectornexus/components'
import { isEmptyString } from '@inspectornexus/core-utils'

interface IExtensionLinkProps {
  href: string
  rel: string
  target?: string
}

interface IDynLinkComponentProps {
  to: string
}
export const DynamicLinkComponent = memo<IDynLinkComponentProps>(
  ({ to, ...rest }) => <NextLink href={to} {...rest} />
)

DynamicLinkComponent.displayName = 'DynamicLinkComponent'

const ExternalLink = styled(Text)<IExtensionLinkProps>`
  text-decoration: none;
`

// ts-prune-ignore-next
export interface ILinkProps extends Omit<IBoxProps, 'as' | 'ref'>, ITextProps {
  children?: ReactNode
  href?: string
  target?: string
}

export const Link = memo<ILinkProps>(({ href, ...rest }) =>
  isEmptyString(href) ? (
    <Text as="a" pointer color="brand" {...rest} />
  ) : typeof href === 'string' && !href.startsWith('/') ? (
    <ExternalLink
      rel="noopener"
      as="a"
      pointer
      color="brand"
      href={href}
      {...rest}
    />
  ) : (
    <NextLink href={href}>
      <Text as="a" pointer color="brand" {...rest} />
    </NextLink>
  )
)

Link.displayName = 'Link'
