import type { ComponentType } from 'react'
import { memo } from 'react'

import { RootContainer } from '@inspectornexus/components'

import { PageLayout } from './PageLayout'
import type { IPageComponentProps } from './PageWrapper.types'

interface IBuildPageComponentProps {
  includeFooterCTA?: boolean
  lightHeader?: boolean
}

export const buildPageComponent = <P extends IPageComponentProps>(
  PageComponent: ComponentType<P>,
  { includeFooterCTA = true, lightHeader = true }: IBuildPageComponentProps
) => {
  const PageWrapper = memo<Omit<P, keyof IPageComponentProps>>(p => {
    const allProps = {
      headerHeight: 50,
      ...p
    } as P
    return (
      <RootContainer>
        <PageLayout
          includeFooterCTA={includeFooterCTA}
          lightHeader={lightHeader}
          headerHeight={50}
        >
          <PageComponent {...allProps} />
        </PageLayout>
      </RootContainer>
    )
  })

  PageWrapper.displayName = `${PageComponent.displayName ?? ''}PageWrapper`

  return PageWrapper
}
