import { memo } from 'react'

import type { IButtonProps } from '@inspectornexus/components'
import { Button as BaseButton } from '@inspectornexus/components'

export const Button = memo<IButtonProps>(p => (
  <BaseButton
    borderRadius={2}
    boxShadow="rgba(64, 62, 80, 0.11) 0px 4px 6px, rgba(14, 13, 13, 0.08) 0px 1px 3px;"
    {...p}
  />
))

Button.displayName = 'Button'
