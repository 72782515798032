import { memo } from 'react'

import { Button } from 'components/Button'
import { Link } from 'components/Link'

import { Box, Flex, Heading, Text } from '@inspectornexus/components'

export const AppFooterCta = memo(() => (
  <Box
    position="relative"
    borderBottom="2px solid rgba(207,215,223,.25);"
    py={6}
    as="article"
  >
    <Flex
      display={['block', 'flex']}
      alignItems="center"
      mx="auto"
      px={4}
      width="100%"
      maxWidth="1040px"
    >
      <Box flex="1 0 50%">
        <Heading fontSize={6} as="h1" lineHeight="45px" my={3}>
          <Text display="block" fontWeight={300} color="brand" as="span">
            Ready to get started?
          </Text>
          Get in touch or create an account.
        </Heading>
      </Box>
      <Flex
        justifyContent={['flex-start', 'flex-end']}
        flex="1 0 50%"
        flexWrap="wrap"
        style={{ margin: '-10px' }}
      >
        <Link href="https://web.inspectornexus.com/web">
          <Button m={2} bg="brand">
            <Text
              color="inherit"
              textTransform="uppercase"
              fontSize={3}
              fontWeight={600}
            >
              Create Free Account
            </Text>
          </Button>
        </Link>
        <Link target="_blank" href="mailto:support@inspectornexus.com">
          <Button m={2} bg="container" color="brand">
            <Text
              color="inherit"
              textTransform="uppercase"
              fontSize={3}
              fontWeight={600}
            >
              Contact Us
            </Text>
          </Button>
        </Link>
      </Flex>
    </Flex>
  </Box>
))

AppFooterCta.displayName = 'AppFooterCta'
