import { memo } from 'react'

import { Link } from 'components/Link'

import styled from '@emotion/styled'
import type { ILinkProps } from '@inspectornexus/components'
import { Box } from '@inspectornexus/components'

const ListLink = styled(Link)`
  transition: color 0.1s;
  cursor: pointer;
  &&:hover {
    color: #32325d;
  }
`

interface IAppFooterNavItemProps extends Omit<ILinkProps, 'children'> {
  href: string
  label: string
  target?: string
}

export const AppFooterNavItem = memo<IAppFooterNavItemProps>(
  ({ label, ...rest }) => (
    <Box as="li" mr={5}>
      <ListLink
        lineHeight="30px"
        fontSize={1}
        pointer
        color="lightText3"
        {...rest}
      >
        {label}
      </ListLink>
    </Box>
  )
)

AppFooterNavItem.displayName = 'AppFooterNavItem'
