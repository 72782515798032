import { memo, useCallback } from 'react'

import type { ILink } from 'utils/navLinks'
import { navLinks } from 'utils/navLinks'

import { Flex } from '@inspectornexus/components'

import { AppHeaderLink } from './AppHeaderLink'

interface IProps {
  lightHeader: boolean
}

export const AppHeaderLinks = memo<IProps>(({ lightHeader }) => {
  const renderLink = useCallback(
    (link: ILink) => (
      <AppHeaderLink lightHeader={lightHeader} linkKey={link.key} {...link} />
    ),
    []
  )

  return (
    <Flex flex="3" display={['none', 'flex']} justifyContent="flex-end">
      {navLinks.map(renderLink)}
    </Flex>
  )
})

AppHeaderLinks.displayName = 'AppHeaderLinks'
