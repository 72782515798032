import { memo } from 'react'

import { DynamicLinkComponent } from 'components/Link'
import dynamic from 'next/dynamic'

import styled from '@emotion/styled'
import { Box, Flex, Link } from '@inspectornexus/components'
import { MonotoneLogo } from '@inspectornexus/icons'

import { AppHeaderLinks } from './AppHeaderLinks'

const AppHeaderMobileLinks = dynamic(
  async () => {
    const Component = await import('./AppHeaderMobileLinks')
    return Component.AppHeaderMobileLinks
  },
  {
    loading: () => null,
    ssr: false
  }
)
const Container = styled(Box)`
  perspective: 2000px;
  z-index: 500;
`
interface IProps {
  headerHeight: number
  lightHeader: boolean
}

export const AppHeader = memo<IProps>(({ headerHeight, lightHeader }) => (
  <Container
    minWidth="400px"
    position="absolute"
    as="header"
    left={0}
    right={0}
    top={lightHeader ? '10px' : 0}
    height={`${headerHeight + (lightHeader ? 0 : 15)}px`}
    style={{ paddingTop: lightHeader ? 0 : '10px' }}
  >
    <Box p={0} mx="auto" maxWidth="1040px" width="100%" as="nav">
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p={0}
        m={0}
        position="relative"
        mx={2}
      >
        <Link DynamicLinkComponent={DynamicLinkComponent} href="/">
          <Flex height="100%" flex="1" ml={1}>
            {lightHeader ? (
              <MonotoneLogo color="transparent" size={50} />
            ) : (
              <MonotoneLogo color="transparent" fill="lightText3" size={50} />
            )}
          </Flex>
        </Link>
        <AppHeaderLinks lightHeader={lightHeader} />
        <AppHeaderMobileLinks lightHeader={lightHeader} />
      </Flex>
    </Box>
  </Container>
))

AppHeader.displayName = 'AppHeader'
