import type { ReactNode } from 'react'
import React, { memo } from 'react'

import { Box } from '@inspectornexus/components'

import { AppFooter } from './AppFooter'
import { AppHeader } from './AppHeader'

interface IProps {
  children: ReactNode
  headerHeight: number
  includeFooterCTA: boolean
  lightHeader: boolean
}

export const PageLayout = memo<IProps>(
  ({ children, headerHeight, lightHeader, includeFooterCTA }: IProps) => (
    <Box minWidth="400px">
      <AppHeader lightHeader={lightHeader} headerHeight={headerHeight} />
      <Box style={{ flex: '1 1 auto' }}>
        <Box display="block" position="relative" overflow="hidden">
          {children}
        </Box>
      </Box>
      <AppFooter includeCTA={includeFooterCTA} />
    </Box>
  )
)

PageLayout.displayName = 'PageLayout'
