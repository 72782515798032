import type { ComponentType } from 'react'

import type { ILinkProps } from '@inspectornexus/components'
import type { IconProps } from '@inspectornexus/icons'
import {
  Award,
  Clipboard,
  HelpCircle,
  Home,
  Login
} from '@inspectornexus/icons'

export interface ILink {
  IconComponent: ComponentType<IconProps>
  key: string
  label: string
  linkProps?: Omit<ILinkProps, 'children'>
  path: string
}

export const navLinks: ILink[] = [
  {
    key: 'home',
    path: '/',
    IconComponent: Home,
    label: 'Home'
  },
  {
    key: 'sampleReports',
    path: '/sampleReports',
    IconComponent: Clipboard,
    label: 'Sample Reports'
  },
  {
    key: 'pricing',
    path: '/pricing',
    IconComponent: Award,
    label: 'Pricing'
  },
  {
    key: 'support',
    path: 'https://help.inspectornexus.com/',
    IconComponent: HelpCircle,
    label: 'Support',
    linkProps: {
      target: '_blank'
    }
  },
  {
    key: 'login',
    path: 'https://web.inspectornexus.com/web',
    IconComponent: Login,
    label: 'Login'
  }
]
